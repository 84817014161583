import React from 'react';
import Card from 'common/ui/Card';
import { formatCurrency } from 'common/util/currency';
import { QuestionIcon } from '@primer/octicons-react';
import ProfitPayoutInfo from '../../ProfitPayoutInfo';
import { InvestmentDetailTransaction } from '../../investmentDetailPageTypes';
import { InvestmentTransactionType } from '../../../shared/investmentTransactionType';
import CPMTable from '../profitBreakdownTable/CPMTable';

interface CpmSummaries {
  amount: number,
  originalAmount: number,
  totalEarlyPayout: number,
  closingAmount: number,
  totalProfit: number,
  customerTake: number,
  companyTake: number,
  transactions: InvestmentDetailTransaction[],
}
const CpmSummaries: React.FC<CpmSummaries> = (details) => {
  const {
    amount,
    originalAmount,
    totalEarlyPayout,
    closingAmount,
    totalProfit,
    customerTake,
    companyTake,
    transactions,
  } = details;
  const companyRate = Number(companyTake) / 100;
  const customerRate = Number(customerTake) / 100;
  const companyProfit = companyRate * totalProfit;
  const customerProfit = customerRate * totalProfit;
  const payoutInfo = transactions
    .filter((item) => item.type === InvestmentTransactionType.Settlements);
  return (
    <>
      <Card title="Investment Summaries">
        <div className="row ">
          <div className="col-4">
            <p>
              Capital
              <span
                className="pl-2"
                data-tooltip-id="formula-tooltip"
                data-tooltip-content={`Initial Capital was: ${formatCurrency(originalAmount, 'two')}`}
                data-tooltip-place="top"
              >
                <QuestionIcon />
              </span>
            </p>
            <h5>
              {formatCurrency(amount, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>

          <div className="col-4">
            <p>
              Total Early Withdrawal
              <span
                className="pl-2"
                data-tooltip-id="formula-tooltip"
                data-tooltip-content="Sum of all early payout"
                data-tooltip-place="top"
              >
                <QuestionIcon />
              </span>
            </p>
            <h5>
              {formatCurrency(totalEarlyPayout, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
          <div className="col-4">
            <p>
              Closing Amount
              <span
                className="pl-2"
                data-tooltip-id="formula-tooltip"
                data-tooltip-content="Invest Amount + Total Profit - Totaly Early Withdrawal"
                data-tooltip-place="top"
              >
                <QuestionIcon />
              </span>
            </p>
            <h5>
              {formatCurrency(closingAmount, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
        </div>
        <hr className="my-2" />
        <div className="row">
          <div className="col-12">
            <h5>Profits</h5>
          </div>
          <div className="col-4">
            <span className="badge badge-success py-2 px-4 font-weight-normal">
              Total Profit
            </span>
            <h5>
              {formatCurrency(totalProfit, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
          <div className="col-4">
            <span className="badge badge-primary py-2 px-4 font-weight-normal">
              Investor
              {' '}
              (
              {customerTake}
              %)
            </span>
            <h5 className="">
              {formatCurrency(customerProfit, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
          <div className="col-4">
            <span className="badge badge-info py-2 px-4 font-weight-normal">
              Company (
              {companyTake}
              %)
            </span>
            <h5 className="">
              {formatCurrency(companyProfit, 'two')}
              {' '}
              <small>USDT</small>
            </h5>
          </div>
        </div>
      </Card>
      <div className="my-4" />
      <CPMTable
        transactions={payoutInfo}
        companyRate={companyTake / 100}
        customerRate={customerTake / 100}
      />
    </>

  );
};

export default CpmSummaries;
